import React, { useMemo, useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdOutlineEdit } from 'react-icons/md';
import {
  useDeleteScheduledMessageMutation,
  useGetScheduledMessagesQuery,
  usePatchScheduledMessageMutation,
} from '@@/services/scheduledMessage';
import {
  ScheduledMessageStatuses,
  translateScheduledMessageStatus,
} from '@@/constants/scheduledMessage';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import EditableCell from '@@/components/Table/EditableCell';
import { Filter, partialMatchFilter } from '@@/components/Table/Filter';

type ScheduledMessageRow = Omit<
  Unpatient.ScheduledMessage,
  'creatorId' | 'userId' | 'eventId'
> & {
  creatorId: string;
  userId: string;
  eventId?: string;
};

const columnHelper = createColumnHelper<ScheduledMessageRow>();

const ScheduledMessagesPage: React.FC = () => {
  const { data: scheduledMessages } = useGetScheduledMessagesQuery();
  const [deleteScheduledMessage, { isSuccess: isDeletionSuccess }] =
    useDeleteScheduledMessageMutation();

  const [patchScheduledMessage, { isSuccess: isPatchSuccess }] =
    usePatchScheduledMessageMutation();

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success('Le message a bien été déprogrammé et ne sera pas envoyé');
      window.location.replace(window.location.href);
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success('Le message a bien été modifié');
      window.location.replace(window.location.href);
    }
  }, [isPatchSuccess]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'creatorId',
        header: () => <p className="mx-2 text-xs sm:text-sm">Créateur</p>,
        filterFn: partialMatchFilter,
        cell: (row) => {
          const { creatorId } = row.getValue();

          if (!creatorId) {
            return null;
          }

          return <p className="mx-2">{creatorId}</p>;
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'userId',
        header: () => <p className="mx-2 text-xs sm:text-sm">Destinataire</p>,
        filterFn: partialMatchFilter,
        cell: (row) => {
          const { userId } = row.getValue();

          if (!userId) {
            return null;
          }

          return <p className="mx-2">{userId}</p>;
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'scheduledAt',
        sortingFn: (rowA, rowB) => {
          return (
            new Date(rowA.original.scheduledAt).getTime() -
            new Date(rowB.original.scheduledAt).getTime()
          );
        },
        header: () => (
          <p className="mx-2 text-xs sm:text-sm">Date d'envoi prévue</p>
        ),
        cell: (row) => {
          const { scheduledAt } = row.getValue();

          const scheduledAtMoment = moment(scheduledAt);
          return (
            <div className="flex flex-col m-2">
              <p className="mx-2 text-xs text-gray-700">
                {scheduledAtMoment.format('DD/MM/YY')}
              </p>
              <p className="text-xs text-white bg-black p-1 rounded-full">
                {scheduledAtMoment.format('HH:mm')}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'sentAt',
        header: () => (
          <p className="mx-2 text-xs sm:text-sm">Date d'envoi réalisée</p>
        ),
        cell: (row) => {
          const { sentAt } = row.getValue();
          if (!sentAt) {
            return <p>-</p>;
          }

          const sentAtMoment = moment(sentAt);
          return (
            <div className="flex flex-col m-2">
              <p className="mx-2 text-xs text-gray-700">
                {sentAtMoment.format('DD/MM/YY')}
              </p>
              <p className="text-xs text-white bg-black p-1 rounded-full">
                {sentAtMoment.format('HH:mm')}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor('body', {
        id: 'body',
        header: () => <p className="mx-2 text-xs sm:text-sm">Message</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 3,
          width: 'w-fit',
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'eventId',
        header: () => <p className="mx-2 text-xs sm:text-sm">Ev. lié</p>,
        filterFn: partialMatchFilter,
        cell: (row) => {
          const { eventId } = row.getValue();

          if (!eventId) {
            return null;
          }

          return <p className="mx-2">{eventId}</p>;
        },
      }),
      columnHelper.accessor('status', {
        header: () => <p className="mx-2 text-xs sm:text-sm">Statut</p>,
        cell: (row) => {
          const status = row.getValue();
          return (
            <p
              className={cx(
                'mx-2 my-2 p-1 rounded-3xl text-xs',
                status ===
                  ScheduledMessageStatuses.NOT_SENT_CONVERSATION_FLOW &&
                  'text-blue-600 bg-blue-100',
                status === ScheduledMessageStatuses.SCHEDULED &&
                  'text-yellow-600 bg-yellow-100',
                [
                  ScheduledMessageStatuses.ERROR,
                  ScheduledMessageStatuses.CANCELED,
                ].includes(status) && 'text-red-600 bg-red-100',
                status === ScheduledMessageStatuses.SENT &&
                  'text-green-600 bg-green-100',
              )}
            >
              {translateScheduledMessageStatus(status)}
            </p>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'edit',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { status, id } = row.getValue();

          switch (status) {
            case ScheduledMessageStatuses.CANCELED:
            case ScheduledMessageStatuses.SENT:
              return null;

            default:
              return (
                <div className="mx-2">
                  <Link
                    to={`/scheduled-messages/${id}`}
                    className="flex flex-row items-center justify-center w-fit border-2 border-green-500 bg-green-500 rounded-3xl p-1"
                    rel="noopener noreferrer"
                  >
                    <MdOutlineEdit color="white" />
                  </Link>
                </div>
              );
          }
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'delete',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { status, id } = row.getValue();

          switch (status) {
            case ScheduledMessageStatuses.CANCELED:
            case ScheduledMessageStatuses.SENT:
              return null;

            default:
              return (
                <div className="mx-2">
                  <button
                    onClick={() => {
                      deleteScheduledMessage(id);
                    }}
                    className="flex flex-row items-center justify-center w-fit border-2 border-red-500 bg-red-500 rounded-3xl p-1"
                  >
                    <TiDeleteOutline color="white" />
                  </button>
                </div>
              );
          }
        },
      }),
    ],
    [deleteScheduledMessage],
  );

  const [showSentMessages, setShowSentMessages] = useState(false);
  const toggleShowSentMessages = useCallback(() => {
    setShowSentMessages((showSentMessages) => !showSentMessages);
  }, [setShowSentMessages]);

  const data = useMemo(() => {
    if (!scheduledMessages) {
      return [];
    }
    return scheduledMessages
      .filter((sm) => {
        if (showSentMessages) {
          return true;
        }
        return sm.status === ScheduledMessageStatuses.SCHEDULED;
      })
      .map((scheduledMessage) => ({
        ...scheduledMessage,
        userId: `${scheduledMessage.userId.firstName} ${scheduledMessage.userId.lastName}`,
        creatorId: `${scheduledMessage.creatorId.firstName} ${scheduledMessage.creatorId.lastName}`,
        eventId: scheduledMessage.eventId?.name,
      }))
      .sort((rowA, rowB) => {
        if (
          rowA.status === ScheduledMessageStatuses.SCHEDULED &&
          rowB.status !== ScheduledMessageStatuses.SCHEDULED
        ) {
          return -1;
        }

        if (
          rowB.status === ScheduledMessageStatuses.SCHEDULED &&
          rowA.status !== ScheduledMessageStatuses.SCHEDULED
        ) {
          return 1;
        }

        return (
          new Date(rowA.scheduledAt).getTime() -
          new Date(rowB.scheduledAt).getTime()
        );
      });
  }, [scheduledMessages, showSentMessages]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    meta: {
      updateData: (id: string, columnId: string, value: string) => {
        const body = {
          [columnId]: value,
        };

        patchScheduledMessage({ id, ...body });
      },
    },
  });

  return (
    <div className="m-4 w-full">
      <div className="block w-fit ml-auto">
        <Link
          to="/scheduled-messages/new"
          className="bg-cyan-400 p-2 rounded-lg h-fit"
        >
          <span className="text-white">Nouveau message programmé</span>
        </Link>
      </div>

      <table className="my-8">
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-center mx-2">
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <span className="pl-2">↑</span>,
                          desc: <span className="pl-2">↓</span>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} />
                      ) : null}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-center mx-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="block mx-auto p-2 mb-10"
        onClick={toggleShowSentMessages}
      >
        <span className="font-main text-main underline">
          {showSentMessages
            ? 'Cacher les messages déjà traités'
            : 'Afficher les messages déjà traités'}
        </span>
      </button>
    </div>
  );
};

export default ScheduledMessagesPage;

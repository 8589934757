export enum SpecialitySlugs {
  ALLERGIST = 'allergist',
  ANESTHESIOLOGIST = 'anesthesiologist',
  ANGIOLOGIST = 'angiologist',
  AUDIOPROTHETIST = 'audioprothetist',
  ' MEDICAL-BIOLOGIST' = 'medical-biologist',
  CARDIOLOGIST = 'cardiologist',
  SURGEON = 'surgeon',
  'CARDIOVASCULAR-SURGEON' = 'cardiovascular-surgeon',
  'DENTIST-SURGEON' = 'dentist-surgeon',
  'HAND-SURGEON' = 'hand-surgeon',
  'MAXILLOFACIAL-SURGEON' = 'maxillofacial-surgeon',
  'ORTHOPEDIC-SURGEON' = 'orthopedic-surgeon',
  'PLASTIC-SURGEON' = 'plastic-surgeon',
  'DIGESTIVE-AND-VISCERAL-SURGEON' = 'digestive-and-visceral-surgeon',
  DENTIST = 'dentist',
  DERMATOLOGIST = 'dermatologist',
  DIETITIAN = 'dietitian',
  ENDOCRINOLOGIST = 'endocrinologist',
  'OCCUPATIONAL-THERAPIST' = 'occupational-therapist',
  GASTROENTEROLOGIST = 'gastroenterologist',
  GENETICIST = 'geneticist',
  GERIATRICIAN = 'geriatrician',
  GYNECOLOGIST = 'gynecologist',
  HEMATOLOGIST = 'hematologist',
  INFECTIOLOGIST = 'infectiologist',
  NURSE = 'nurse',
  'SPORTS-DOCTOR' = 'sports-doctor',
  'AESTHETIC-DOCTOR' = 'aesthetic-doctor',
  'GENERAL-PRACTICIAN' = 'general-practician',
  INTENSIVIST = 'intensivist',
  'DOCTOR-SEXOLOGIST' = 'doctor-sexologist',
  'INFECTIOUS-AND-TROPICAL-DISEASE-SPECIALIST' = 'infectious-and-tropical-disease-specialist',
  'EMERGENCY-DOCTOR' = 'emergency-doctor',
  NEPHROLOGIST = 'nephrologist',
  'NEURO-PSYCHIATRIST' = 'neuro-psychiatrist',
  NEUROSURGEON = 'neurosurgeon',
  NEUROLOGIST = 'neurologist',
  NUTRITIONIST = 'nutritionist',
  OBSTETRICIAN = 'obstetrician',
  ONCOLOGIST = 'oncologist',
  OPHTALMOLOGIST = 'ophtalmologist',
  OPTICIAN = 'optician',
  OPTOMETRIST = 'optometrist',
  'ENT-DOCTOR' = 'ent-doctor',
  ORTHODONTIST = 'orthodontist',
  ORTHOPHONIST = 'orthophonist',
  ORTHOPTIST = 'orthoptist',
  OSTEOPATH = 'osteopath',
  PEDIATRICIAN = 'pediatrician',
  'GASTROENTEROLOGIST-PEDIATRICIAN' = 'gastroenterologist-pediatrician',
  'NEONATAL-PEDIATRICIAN' = 'neonatal-pediatrician',
  'PULMONOLOGY-PEDIATRICIAN' = 'pulmonology-pediatrician',
  'CHILD-PSYCHIATRIST' = 'child-psychiatrist',
  PHARMACIST = 'pharmacist',
  PHYSIATRIST = 'physiatrist',
  'PHYSICAL-THERAPIST' = 'physical-therapist',
  PNEUMOLOGIST = 'pneumologist',
  PODIATRIST = 'podiatrist',
  PSYCHIATRIS = 'psychiatrist',
  PSYCHOLOGIST = 'psychologist',
  PSYCHOMETRICIAN = 'psychometrician',
  PSYCHOTHERAPIST = 'psychotherapist',
  RADIOLOGIST = 'radiologist',
  RADIOTHERAPIST = 'radiotherapist',
  RHEUMATOLOGIST = 'rheumatologist',
  MIDWIFE = 'midwife',
  SEXOLOGIST = 'sexologist',
  'ADDICTION-SPECIALIST' = 'addiction-specialist',
  'INTERNAL-MEDICINE-SPECIALIST' = 'internal-medicine-specialist',
  'SLEEP-SPECIALIST' = 'sleep-specialist',
  STOMATOLOGIST = 'stomatologist',
  TOBACCOLOGIST = 'tobaccologist',
  THERAPIST = 'therapist',
  'COUPLE-THERAPIST' = 'couple-therapist',
  UROLOGIST = 'urologist',
}

export const specialities = [
  {
    name: 'Allergologue',
    slug: 'allergist',
    code: '01 Allergologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Anesthésiste',
    slug: 'anesthesiologist',
    code: '02 Anesthésiologie - réanimation chirurgicale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Angiologue',
    slug: 'angiologist',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Audioprothésiste',
    slug: 'audioprothetist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Biologiste médical',
    slug: 'medical-biologist',
    code: '38 Médecin biologiste',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Cardiologue',
    slug: 'cardiologist',
    code: '03 Pathologie cardio-vasculaire',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien',
    slug: 'surgeon',
    code: '04 Chirurgie générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien cardiovasculaire',
    slug: 'cardiovascular-surgeon',
    code: '47 Chirurgie thoracique et cardio-vasculaire',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien Dentiste',
    slug: 'dentist-surgeon',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Chirurgien de la main',
    slug: 'hand-surgeon',
    code: '10 Chirurgie des mains',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien maxillo-facial',
    slug: 'maxillofacial-surgeon',
    code: '44 Chirurgie maxillo-faciale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien orthopédique',
    slug: 'orthopedic-surgeon',
    code: '41 Chirurgien orthopédique',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien plastique',
    slug: 'plastic-surgeon',
    code: '46 Chirurgie plastique reconstructrice et esthétique',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Chirurgien viscéral et digestif',
    slug: 'digestive-and-visceral-surgeon',
    code: '49 Chirurgie viscérale et digestive',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Dentiste',
    slug: 'dentist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Dermatologue',
    slug: 'dermatologist',
    code: '05 Dermatologie et vénérologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Diététicien',
    slug: 'dietitian',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Endocrinologue',
    slug: 'endocrinologist',
    code: '55 Endocrinologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Ergothérapeute',
    slug: 'occupational-therapist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Gastroentérologue',
    slug: 'gastroenterologist',
    code: '08 Gastroentérologue',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Généticien',
    slug: 'geneticist',
    code: '78 Génétique médicale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Gériatre',
    slug: 'geriatrician',
    code: '34 Gériatrie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Gynécologue',
    slug: 'gynecologist',
    code: '70 Gynécologie Médicale',
    isDirectAccess: true,
    isRefundable: true,
  },
  {
    name: 'Hématologue',
    slug: 'hematologist',
    code: '71 Hématologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Infectiologue',
    slug: 'infectiologist',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Infirmier',
    slug: 'nurse',
    code: '24 Infirmier',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Médecin du sport',
    slug: 'sports-doctor',
    code: '22 Médecin du sport',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Médecin esthétique',
    slug: 'aesthetic-doctor',
    code: '00',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Médecin généraliste',
    slug: 'general-practician',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Médecin réanimateur',
    slug: 'intensivist',
    code: '20',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Médecin sexologue',
    slug: 'doctor-sexologist',
    code: '39 Médecin généraliste / Sexologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Médecin spécialiste des maladies infectieuses et tropicales',
    slug: 'infectious-and-tropical-disease-specialist',
    code: '81',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Médecin urgentiste',
    slug: 'emergency-doctor',
    code: '83',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Néphrologue',
    slug: 'nephrologist',
    code: '35 Néphrologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Neuro-psychiatre',
    slug: 'neuro-psychiatrist',
    code: '17',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Neurochirurgien',
    slug: 'neurosurgeon',
    code: '16 Neurochirurgie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Neurologue',
    slug: 'neurologist',
    code: '10 Neurochirurgie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Nutritionniste',
    slug: 'nutritionist',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Obstétricien',
    slug: 'obstetrician',
    code: '77 Obstétrique',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Oncologiste',
    slug: 'oncologist',
    code: '73 Oncologie médicale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Ophtalmologue',
    slug: 'ophtalmologist',
    code: '15 Ophtalmologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Opticien',
    slug: 'optician',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Optométriste',
    slug: 'optometrist',
    code: '64',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'ORL',
    slug: 'ent-doctor',
    code: '11 Oto-rhino laryngologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Orthodontiste',
    slug: 'orthodontist',
    code: 'Orthodontie',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Orthophoniste',
    slug: 'orthophonist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Orthoptiste',
    slug: 'orthoptist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Ostéopathe',
    slug: 'osteopath',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Pédiatre',
    slug: 'pediatrician',
    code: '12 Pédiatrie',
    isDirectAccess: true,
    isRefundable: true,
  },
  {
    name: 'Pédiatre gastroentérologue',
    slug: 'gastroenterologist-pediatrician',
    code: '73  Pédiatrie-gastroentérologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Pédiatre néonatal',
    slug: 'neonatal-pediatrician',
    code: '12 Pédiatrie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Pediatre pneumologue',
    slug: 'pulmonology-pediatrician',
    code: '76 Pédiatrie-pneumologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Pédopsychiatre',
    slug: 'child-psychiatrist',
    code: "75 Psychiatrie de l'enfant et de l'adolescent",
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Pharmacien',
    slug: 'pharmacist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Physiatre',
    slug: 'physiatrist',
    code: '31 Médecine physique et de réadaptation',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Kinésithérapeute',
    slug: 'physical-therapist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Pneumologue',
    slug: 'pneumologist',
    code: '13 Pneumologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Podologue',
    slug: 'podiatrist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Psychiatre',
    slug: 'psychiatrist',
    code: '33 Psychiatrie générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Psychologue',
    slug: 'psychologist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Psychométricien',
    slug: 'psychometrician',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Psychothérapeute',
    slug: 'psychotherapist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Radiologue',
    slug: 'radiologist',
    code: '06',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Radiothérapeute',
    slug: 'radiotherapist',
    code: '76 Radiothérapie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Rhumatologue',
    slug: 'rheumatologist',
    code: '14 Rhumatologie',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Sage femme',
    slug: 'midwife',
    code: '21 Sage-femme',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Sexologue',
    slug: 'sexologist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Spécialiste des addictions',
    slug: 'addiction-specialist',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Spécialiste en médecine interne',
    slug: 'internal-medicine-specialist',
    code: '09',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Médecin du sommeil',
    slug: 'sleep-specialist',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Stomatologue',
    slug: 'stomatologist',
    code: '18',
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Tabacologue',
    slug: 'tobaccologist',
    code: '01 Médecine générale',
    isDirectAccess: false,
    isRefundable: true,
  },
  {
    name: 'Thérapeute',
    slug: 'therapist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Thérapeute de couple',
    slug: 'couple-therapist',
    code: undefined,
    isDirectAccess: false,
    isRefundable: false,
  },
  {
    name: 'Urologue',
    slug: 'urologist',
    code: '16 Chirurgie urologique',
    isDirectAccess: false,
    isRefundable: true,
  },
];

export const selectInputSpecialities = specialities.map((speciality) => ({
  id: speciality.slug,
  label: speciality.name,
}));

export const translateSpeciality = (slug: string) => {
  const speciality = specialities.find(
    (speciality) => speciality.slug === slug,
  );
  return speciality?.name || slug;
};

import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import StandardInput from '@@/components/Inputs/StandardInput';

const ALDItem: React.FC<{
  form: any;
  index: number;
  removeItem: (arg: number) => void;
}> = ({ form, index, removeItem }) => {
  const { register } = form;

  return (
    <div className="w-[500px]">
      <div className="flex flex-row justify-between mb-10 text-white font-main bg-cyan-400 p-2 rounded-lg">
        <p className="text-lg font-semibold">Elément ALD #{index + 1}</p>
        <button type="button" onClick={() => removeItem(index)}>
          <RiDeleteBinLine size={24} />
        </button>
      </div>
      <div className="flex flex-col space-y-10">
        <StandardInput
          register={register}
          id={`aldItems[${index}].text`}
          label="Texte"
          type="textarea"
          placeholder="Intitulé"
        />
        <StandardInput
          register={register}
          id={`aldItems[${index}].comment`}
          label="Commentaire"
          type="textarea"
          placeholder="Commentaire"
        />
      </div>
    </div>
  );
};

const StandardItem: React.FC<{
  form: any;
  index: number;
  removeItem: (arg: number) => void;
}> = ({ form, index, removeItem }) => {
  const { register } = form;

  return (
    <div className="w-[500px]">
      <div className="flex flex-row justify-between mb-10 text-white font-main bg-cyan-400 p-2 rounded-lg">
        <p className="text-lg font-semibold">Elément libre #{index + 1}</p>
        <button type="button" onClick={() => removeItem(index)}>
          <RiDeleteBinLine size={24} />
        </button>
      </div>
      <div className="flex flex-col space-y-10">
        <StandardInput
          register={register}
          id={`standardItems[${index}].text`}
          label="Texte"
          type="textarea"
          placeholder="Intitulé"
        />
        <StandardInput
          register={register}
          id={`standardItems[${index}].comment`}
          label="Commentaire"
          type="textarea"
          placeholder="Commentaire"
        />
      </div>
    </div>
  );
};

export const BizonePrescriptionForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const { control } = form;

  const {
    fields: standardItems,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'standardItems',
  });

  const {
    fields: aldItems,
    append: appendAldItem,
    remove: removeAldItem,
  } = useFieldArray({
    control,
    name: 'aldItems',
  });

  return (
    <div className="flex flex-col w-fit mt-10">
      <div className="">
        <div className="border-b border-t py-2 my-4">
          <h2 className="text-2xl font-main text-main">Partie ALD</h2>
        </div>

        <ul className="flex flex-col space-y-12">
          {aldItems.map((field, index) => (
            <ALDItem
              key={field.id}
              form={form}
              index={index}
              removeItem={removeAldItem}
            />
          ))}
        </ul>

        <button
          type="button"
          className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base flex flex-row items-center w-fit"
          onClick={() => appendAldItem({})}
        >
          <IoIosAddCircleOutline size={24} />
          <span className="ml-2">Ajouter un élément (ALD)</span>
        </button>
      </div>

      <div className="mt-10">
        <div className="border-b border-t py-2 my-4">
          <h2 className="text-2xl font-main text-main">Partie Libre</h2>
        </div>
        <ul className="flex flex-col space-y-12">
          {standardItems.map((field, index) => (
            <StandardItem
              key={field.id}
              form={form}
              index={index}
              removeItem={removeItem}
            />
          ))}
        </ul>

        <button
          type="button"
          className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base flex flex-row items-center w-fit"
          onClick={() => appendItem({})}
        >
          <IoIosAddCircleOutline size={24} />
          <span className="ml-2">Ajouter un élément libre</span>
        </button>
      </div>
    </div>
  );
};

import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { GrDocumentUpload } from 'react-icons/gr';
import { TiDelete } from 'react-icons/ti';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectPractitionerContactInput from '@@/components/Inputs/SelectPractitionerContactInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';
import CheckboxInput from '@@/components/Inputs/CheckboxInput';
import { formatContactAddress } from '@@/utils/address';

export const schema = yup
  .object({
    name: yup.string().required("Veuillez entrer le nom de l'évènement"),
    notes: yup.string(),
    location: yup.string(),
    targetPractitioner: yup.string(),
    startDate: yup
      .string()
      .required("Veuillez entrer la date de début de l'évènement"),
    endDate: yup
      .string()
      .required("Veuillez entrer la date de fin de l'évènement"),
    shouldSendCalendarInvite: yup.boolean(),
    shouldSendScheduledMessage: yup.boolean(),
    scheduledMessageDate: yup.string(),
    scheduledMessageBody: yup.string(),
  })
  .required();

const EventForm: React.FC<{
  form: any;
  birthDate?: Date;
  existingAttachment?: Unpatient.Document;
  onDeleteDocument: () => void;
}> = ({ form, birthDate, existingAttachment, onDeleteDocument }) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [startDate, endDate, attachments, shouldSendScheduledMessage] = watch([
    'startDate',
    'endDate',
    'attachments',
    'shouldSendScheduledMessage',
  ]);

  useEffect(() => {
    if (typeof startDate?.getTime === 'function') {
      setValue('endDate', new Date(startDate.getTime() + 30 * 60 * 1000));
    }
  }, [startDate, setValue]);

  useEffect(() => {
    if (typeof endDate?.getTime === 'function' && shouldSendScheduledMessage) {
      setValue(
        'scheduledMessageDate',
        new Date(endDate.getTime() + 2 * 60 * 60 * 1000),
      );
    }
  }, [endDate, shouldSendScheduledMessage, setValue]);

  const birthdayWarning = useMemo(() => {
    if (!birthDate) {
      return false;
    }

    const mStartDate = moment(startDate);
    const mEndDate = moment(endDate);
    const mBirthDate = moment(birthDate).year(mStartDate.year());

    return mBirthDate.isBetween(mStartDate, mEndDate, null, '[]');
  }, [birthDate, startDate, endDate]);

  return (
    <div className="mt-8 mx-4">
      {birthdayWarning && (
        <p className="font-main text-orange-400">
          Attention, le patient a son anniversaire à cette date
        </p>
      )}

      <div key="event-form" className="grid grid-cols-3 gap-14">
        <StandardInput
          register={register}
          id="name"
          label="Nom de l'événement (*)"
          placeholder="Nom de l'événement"
          error={errors?.name}
          type="textarea"
          rows={3}
        />

        <StandardInput
          register={register}
          id="location"
          label="Localisation de l'événement"
          placeholder="Localisation de l'événement"
          error={errors?.location}
          type="textarea"
          rows={3}
        />
        <StandardInput
          register={register}
          id="notes"
          label="Notes"
          placeholder="Notes"
          error={errors?.notes}
          rows={3}
          type="textarea"
        />

        <DatePickerInput
          id="startDate"
          control={control}
          label="Date de début de l'événement (*)"
          showTimeSelect
          error={errors?.startDate}
        />
        <DatePickerInput
          id="endDate"
          control={control}
          label="Date de fin de l'événement (*)"
          showTimeSelect
          error={errors?.endDate}
        />

        <div className="-mt-7">
          <SelectPractitionerContactInput
            register={register}
            id="targetPractitioner"
            label="Praticien à consulter"
            placeholder="Choisissez un praticien"
            cb={(contact: Unpatient.PractitionerContact) => {
              const location = formatContactAddress(contact);
              if (location) {
                setValue('location', location);
              }
            }}
          />
        </div>
      </div>

      <div className="my-4">
        <CheckboxInput
          register={register}
          id="shouldSendCalendarInvite"
          label="Envoyer une invitation au patient"
        />
      </div>

      <div className="my-4">
        <CheckboxInput
          register={register}
          id="shouldSendScheduledMessage"
          label="Ajouter un message programmé"
        />

        {shouldSendScheduledMessage && (
          <div className="mt-10 w-[600px]">
            <StandardInput
              register={register}
              id="scheduledMessageBody"
              label="Message programmé (*)"
              placeholder="Message programmé (*)"
              error={errors?.scheduledMessageBody}
              type="textarea"
              rows={3}
            />

            <div className="mt-10">
              <DatePickerInput
                id="scheduledMessageDate"
                control={control}
                label="Date de d'envoi (*)"
                showTimeSelect
                error={errors?.scheduledMessageDate}
              />
            </div>
          </div>
        )}
      </div>

      {!existingAttachment ? (
        <label
          htmlFor="attachments"
          className="my-8 flex flex-row justify-between items-center text-main font-main cursor-pointer"
        >
          <div className="flex flex-row items-center">
            <GrDocumentUpload size={30} />
            <span className={cx('ml-2 underline')}>
              Ajouter une pièce jointe
            </span>

            <Controller
              control={control}
              name="attachments"
              render={({ field }) => {
                return (
                  <input
                    {...field}
                    value={field.value?.fileName}
                    onChange={(event) => {
                      field.onChange(event.target?.files);
                    }}
                    type="file"
                    id="attachments"
                    className="hidden"
                  />
                );
              }}
            />
          </div>

          {!!attachments?.length && (
            <div className="">
              {Array.from(attachments).map((file: any, i) => (
                <div key={i} className="flex flex-row items-center">
                  <p className="font-main text-main">{file.name}</p>
                  <button
                    className="ml-4"
                    onClick={() => setValue('attachments', undefined)}
                  >
                    <TiDelete className="text-red-500" size={20} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </label>
      ) : (
        <div className="mt-6">
          <p className="font-main text-main underline">Pièce jointe :</p>
          <div className="flex flex-row items-center mt-1">
            <p className="font-main text-main">{existingAttachment.name}</p>
            <button className="ml-4" onClick={onDeleteDocument}>
              <TiDelete className="text-red-500" size={20} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventForm;

import { io } from 'socket.io-client';
import { store } from '@@/store';
import { api } from '@@/services/api';
import { Statuses } from '@@/constants/whatsapp';
import toast from 'react-hot-toast';

const standardReconnectTimeout = 2000;
const maxReconnectTimeout = 60000;

class SocketIOManager {
  ws: any;
  reconnectTimeout: number;

  constructor() {
    this.reconnectTimeout = standardReconnectTimeout;
    this.init();
  }

  init = () => {
    const token = store.getState().auth.accessToken;
    if (!token) {
      setTimeout(() => {
        this.init();
      }, this.reconnectTimeout);
      return;
    }

    this.ws = io(import.meta.env.VITE_API_URL, {
      transports: ['websocket'],
      secure: true,
      auth: {
        token,
      },
    });

    this.ws.on('connect', () => {
      this.reconnectTimeout = standardReconnectTimeout;
    });

    this.ws.on('connect_error', () => {
      this.ws.disconnect();
      this.reconnectTimeout = Math.min(
        this.reconnectTimeout * 2,
        maxReconnectTimeout,
      );

      setTimeout(() => {
        this.init();
      }, this.reconnectTimeout);
    });

    this.registerEvents();

    this.ws.connect();
  };

  getWS = () => this.ws;

  private registerEvents = () => {
    if (!this.ws) {
      return;
    }

    this.ws.on('qr', () => {
      store.dispatch(api.util.invalidateTags(['Chats']));
    });

    this.ws.on('status', (s: Statuses) => {
      if (s === Statuses.READY) {
        store.dispatch(api.util.invalidateTags(['Chats']));
      }
    });

    this.ws.on('msg', (msg: Unpatient.ChatMessage) => {
      setTimeout(() => {
        // API needs a delay to be up to date
        store.dispatch(api.util.invalidateTags(['ChatMessages', 'Chats']));
      }, 1000);
    });

    this.ws.on('update-medical-record-started', () => {
      toast.loading(`Dossier médical en cours de mise à jour...`, {
        position: 'top-center',
        duration: 5000,
      });
    });

    this.ws.on('user', (user: Unpatient.User) => {
      store.dispatch(api.util.invalidateTags(['Users']));
      toast.success(
        `Le dossier médical de ${user.firstName} ${user.lastName} a été mis à jour !`,
        {
          position: 'top-right',
        },
      );
    });

    this.ws.on('create-plan-started', () => {
      toast.loading(`Un nouveau plan de soins est en cours de conception...`, {
        position: 'top-center',
        duration: 5000,
      });
    });

    this.ws.on('plan', (plan: Unpatient.Plan) => {
      store.dispatch(api.util.invalidateTags(['Plans']));
      toast.success(`Un nouveau plan de soins est disponible !`, {
        position: 'top-right',
      });
    });

    this.ws.on('create-suggestion-started', () => {
      toast.loading(`Une nouvelle suggestion est en cours de conception...`, {
        position: 'top-center',
        duration: 1000,
      });
    });

    this.ws.on('suggestion', (suggestion: Unpatient.Suggestion) => {
      store.dispatch(api.util.invalidateTags(['Suggestions']));
      toast.success(`Une nouvelle suggestion est disponible !`, {
        position: 'top-right',
      });
    });

    this.ws.on('extract-tasks-started', () => {
      toast.loading(`Extraction de tâches en cours...`, {
        position: 'top-center',
        duration: 1000,
      });
    });

    this.ws.on('refresh-tasks', () => {
      store.dispatch(api.util.invalidateTags(['Tasks']));
    });

    this.ws.on('new-patient', (patient: Unpatient.User) => {
      toast.success(
        `La patient ${patient.firstName} ${patient.lastName} a été créé`,
        {
          position: 'top-right',
        },
      );
    });
  };
}

export default new SocketIOManager();

import React from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectCouponInput from '@@/components/Inputs/SelectCouponInput';
import SelectInput from '@@/components/Inputs/SelectInput';

export const schema = yup.object({
  trialPeriodInDays: yup
    .number()
    .min(0)
    .nullable(true)
    .transform((_, val?: number) => {
      if (val) {
        return Number(val);
      }
    }),
  coupon: yup.string(),
  period: yup.string().required('Veuillez sélectionner une fréquence'),
});

const InvoiceForm: React.FC<{ form: any }> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <form className="grid grid-cols-3 gap-4">
      <SelectInput
        register={register}
        id="period"
        label="Fréquence (*)"
        options={[
          {
            id: 'month',
            label: 'Mensuelle',
          },
          {
            id: 'year',
            label: 'Annuelle',
          },
        ]}
        error={errors?.periof}
      />
      <div className="mt-7">
        <StandardInput
          register={register}
          id="trialPeriodInDays"
          label="Période d'essai en jours"
          type="text"
          placeholder="trialPeriodInDays (facultatif)"
          error={errors?.trialPeriodInDays}
        />
      </div>

      <SelectCouponInput
        register={register}
        id="coupon"
        label="Discount"
        placeholder="Discount"
        error={errors?.coupon}
      />
    </form>
  );
};

export default InvoiceForm;

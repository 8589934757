import React from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useFetchUserCalendarQuery } from '@@/services/user';
import Loader from '@@/components/Loader';

const localizer = momentLocalizer(moment);

const ICSCalendarPage: React.FC = () => {
  const { patientId } = useParams();
  const { data: events, isLoading } = useFetchUserCalendarQuery(
    patientId || '',
    {
      skip: !patientId,
    },
  );

  if (isLoading) {
    return (
      <div className="m-8 flex flex-row items-center justify-center w-full">
        <Loader />
        <p className="ml-2 font-main text-main text-lg">Chargement...</p>
      </div>
    );
  }

  if (!events?.length) {
    return null;
  }

  return (
    <div className="mx-auto my-4">
      <Calendar
        localizer={localizer}
        events={events.map((event) => ({
          ...event,
          start: moment(event.start).toDate(),
          end: moment(event.end).toDate(),
        }))}
        startAccessor="start"
        endAccessor="end"
        defaultView="agenda"
        messages={{
          week: 'Semaine',
          work_week: 'Semaine de travail',
          day: 'Jour',
          month: 'Mois',
          previous: 'Antérieur',
          next: 'Prochain',
          today: `Aujourd'hui`,
          agenda: 'Ordre du jour',

          showMore: (total) => `+${total} plus`,
        }}
      />
    </div>
  );
};

export default ICSCalendarPage;

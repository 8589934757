import { api } from '@@/services/api';

export const prescriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBiologyCategories: builder.query<{ id: string; label: string }[], void>({
      query: () => `prescriptions/biology/categories`,
    }),
    getBiologyExams: builder.query<{ id: string; label: string }[], string>({
      query: (categoryId) => `prescriptions/biology/exams/${categoryId}`,
    }),
    getImageryCategories: builder.query<{ id: string; label: string }[], void>({
      query: () => `prescriptions/imagery/categories`,
    }),
    getImageryExams: builder.query<{ id: string; label: string }[], string>({
      query: (categoryId) => `prescriptions/imagery/exams/${categoryId}`,
    }),
    getPrescription: builder.query<Unpatient.Prescription, string>({
      query: (prescriptionId) => `prescriptions/${prescriptionId}`,
      providesTags: ['Prescriptions'],
    }),
    getUserPrescriptions: builder.query<Unpatient.Prescription[], string>({
      query: (userId) => `users/${userId}/prescriptions`,
      providesTags: ['Prescriptions'],
    }),
    createPrescription: builder.mutation<
      Unpatient.Prescription,
      Partial<Unpatient.Prescription>
    >({
      query: (body) => {
        return {
          url: `prescriptions`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Prescriptions'],
    }),
    deletePrescription: builder.mutation<{}, string>({
      query: (prescriptionId) => ({
        url: `prescriptions/${prescriptionId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Prescriptions'],
    }),
    patchPrescription: builder.mutation<
      Unpatient.Prescription,
      Partial<Unpatient.Prescription>
    >({
      query: ({ id, ...body }) => ({
        url: `/prescriptions/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Prescriptions', 'PrescriptionTemplates'],
    }),
    getMyPrescriptionTemplates: builder.query<Unpatient.Prescription[], void>({
      query: () => `prescriptions/templates`,
      providesTags: ['PrescriptionTemplates'],
    }),
    viewPrescriptionPDF: builder.query<string, string>({
      query: (prescriptionId) => ({
        url: `prescriptions/${prescriptionId}/pdf`,
        responseHandler: async (response) => {
          const contentType = response.headers.get('Content-Type');
          const blob = await response.blob();

          return URL.createObjectURL(
            contentType ? new Blob([blob], { type: contentType }) : blob,
          );
        },
      }),
    }),
  }),
});

export const {
  useGetPrescriptionQuery,
  useGetUserPrescriptionsQuery,
  useCreatePrescriptionMutation,
  useDeletePrescriptionMutation,
  usePatchPrescriptionMutation,
  useGetBiologyCategoriesQuery,
  useGetBiologyExamsQuery,
  useGetImageryCategoriesQuery,
  useGetImageryExamsQuery,
  useGetMyPrescriptionTemplatesQuery,
  useViewPrescriptionPDFQuery,
} = prescriptionApi;

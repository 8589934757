import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { MdOutlinePayment } from 'react-icons/md';
import {
  useGetAllPatientsQuery,
  useSearchPatientsQuery,
} from '@@/services/user';
import { getAge } from '@@/utils/date';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Statuses } from '@@/constants/user';
import { MIN_SEARCH_LENGTH } from '@@/constants/search';
import StandardInput from '@@/components/Inputs/StandardInput';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import SectionTitle from '@@/components/SectionTitle';

const schema = yup
  .object({
    patientName: yup
      .string()
      .min(
        MIN_SEARCH_LENGTH,
        `Le nom doit contenir au moins ${MIN_SEARCH_LENGTH} caractères`,
      )
      .required("Veuillez entrer le nom d'un patient"),
  })
  .required();

const PatientCard: React.FC<{ patient: Unpatient.User }> = ({ patient }) => {
  const cutOffDate = useMemo(() => moment().subtract(10, 'days').unix(), []);
  const patientClass = useMemo(() => {
    if (patient.hasEventToday) {
      return 'bg-yellow-100 border-yellow-200';
    }

    if (!!patient.activeSubscription) {
      if (!!patient.events?.length) {
        return 'bg-green-100 border-green-200';
      }

      const didExchangeMessagesRecently =
        patient.lastMessage?.timestamp &&
        patient.lastMessage.timestamp > cutOffDate;

      if (didExchangeMessagesRecently) {
        return 'bg-blue-100 border-blue-200';
      }

      return 'bg-red-100 border-red-200';
    }

    if (patient.status === Statuses.ACTIVE) {
      return 'bg-orange-100 border-orange-200';
    }

    return 'bg-gray-100 border-gray-200';
  }, [patient, cutOffDate]);

  const phone = useMemo(() => patient?.wsPhone || patient?.phone, [patient]);

  return (
    <Link
      key={patient.id}
      to={phone ? `/conversations/c.us/${phone}` : `/patients/${patient.id}`}
      className={cx(
        'relative m-2 p-4 rounded-lg border flex flex-row items-center w-fit',
        patientClass,
      )}
    >
      {patient.activeSubscription ? (
        <MdOutlinePayment className="absolute top-1 left-1 text-green-500" />
      ) : (
        <MdOutlinePayment className="absolute top-1 left-1 text-red-500" />
      )}
      <AuthenticatedImage
        documentId={patient.photoDocumentId}
        placeholder="/user-profile-placeholder.jpg"
        alt={`${patient.firstName} ${patient.lastName}`}
        className="w-16 h-16 object-cover rounded-full"
      />

      <div className="flex flex-col ml-2 font-main text-main font-semibold">
        <p className="text-lg">
          {patient.firstName} {patient.lastName}
        </p>
        {patient.medicalRecord?.birthDate && (
          <p>{getAge(patient.medicalRecord.birthDate)} ans</p>
        )}

        {!!patient.events?.length && (
          <p>{patient.events.length} événement(s) à venir</p>
        )}

        {!patient.events?.length &&
          !!patient.lastMessage &&
          !!patient.activeSubscription && (
            <p className="text-xs italic">
              Dernier message&nbsp;
              {moment.unix(patient.lastMessage.timestamp).fromNow()}
            </p>
          )}
      </div>
    </Link>
  );
};

const PatientsPage: React.FC = () => {
  const { data: allPatients, isLoading: patientsAreLoading } =
    useGetAllPatientsQuery();

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<{
    patientName: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      patientName: '',
    },
  });
  const patientName = watch('patientName');
  const { data: searchedPatients } = useSearchPatientsQuery(patientName, {
    skip: patientName?.length < MIN_SEARCH_LENGTH,
  });

  const patients = useMemo(() => {
    if (patientName?.length < MIN_SEARCH_LENGTH) {
      return allPatients;
    }

    return searchedPatients;
  }, [searchedPatients, allPatients, patientName]);

  const cutOffDate = useMemo(() => moment().subtract(10, 'days').unix(), []);

  const patientsBlock = useMemo(() => {
    if (patientsAreLoading) {
      return (
        <p className="mt-16 text-center font-main text-main">Chargement...</p>
      );
    }

    if (!patients?.length) {
      return (
        <p className="mt-16 text-center font-main text-main">
          Aucun patient trouvé
        </p>
      );
    }

    const patientWithEventsToday = patients.filter((p) => p.hasEventToday);

    return (
      <div>
        {!!patientWithEventsToday?.length && (
          <>
            <SectionTitle
              title="Patient(s) ayant un évènement prévu aujourd'hui"
              className="mt-10 mb-2"
            />
            <div className="flex flex-row w-full flex-wrap">
              {patientWithEventsToday.map((patient) => (
                <PatientCard key={patient.id} patient={patient} />
              ))}
            </div>
          </>
        )}

        <SectionTitle
          title="Patient(s) ayant besoin de plus de suivi"
          className="mt-10 mb-2"
        />
        <div className="flex flex-row w-full flex-wrap">
          {patients
            .filter((p) => {
              const didExchangeMessagesRecently =
                p.lastMessage?.timestamp &&
                p.lastMessage.timestamp > cutOffDate;

              return (
                p.status === Statuses.ACTIVE &&
                !p.eventsCount &&
                !!p.activeSubscription &&
                !didExchangeMessagesRecently
              );
            })
            .map((patient) => (
              <PatientCard key={patient.id} patient={patient} />
            ))}
        </div>

        <SectionTitle title="Patients suivis" className="mt-10 mb-2" />
        <div className="flex flex-row w-full flex-wrap">
          {patients
            .filter((p) => {
              const hasSubscriptionAnddidExchangeMessagesRecently =
                !!p.activeSubscription &&
                p.lastMessage?.timestamp &&
                p.lastMessage.timestamp > cutOffDate;

              return (
                p.status === Statuses.ACTIVE &&
                (!!p.eventsCount ||
                  hasSubscriptionAnddidExchangeMessagesRecently) &&
                !p.hasEventToday
              );
            })
            .map((patient) => (
              <PatientCard key={patient.id} patient={patient} />
            ))}
        </div>

        <SectionTitle
          title="Patient(s) à relancer pour s'abonner"
          className="mt-10 mb-2"
        />
        <div className="flex flex-row w-full flex-wrap">
          {patients
            .filter(
              (p) =>
                p.status === Statuses.ACTIVE &&
                p.allEventsCount &&
                !p.eventsCount &&
                !p.activeSubscription,
            )
            .map((patient) => (
              <PatientCard key={patient.id} patient={patient} />
            ))}
        </div>

        <SectionTitle
          title="Patient(s) à relancer pour prendre RDV"
          className="mt-10 mb-2"
        />
        <div className="flex flex-row w-full flex-wrap">
          {patients
            .filter(
              (p) =>
                p.status === Statuses.ACTIVE &&
                !p.allEventsCount &&
                !p.activeSubscription,
            )
            .map((patient) => (
              <PatientCard key={patient.id} patient={patient} />
            ))}
        </div>

        <SectionTitle title="Patients inactifs" className="mt-10 mb-2" />
        <div className="flex flex-row w-full flex-wrap">
          {patients
            .filter((p) => p.status === Statuses.INACTIVE)
            .map((patient) => (
              <PatientCard key={patient.id} patient={patient} />
            ))}
        </div>
      </div>
    );
  }, [patients, patientsAreLoading]);

  return (
    <div className="w-full m-4 mt-8 h-full overflow-scroll">
      <div className="flex flex-row justify-between items-center">
        <div className="w-96">
          <StandardInput
            register={register}
            id="patientName"
            label="Rechercher un patient"
            type="text"
            placeholder="Rechercher un patient"
            error={errors.patientName}
          />
        </div>
        <div className="flex flex-row space-x-4">
          <Link
            to="/patients/new"
            className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center"
          >
            <IoIosAddCircleOutline size={24} color="white" />
            <span className="text-white ml-1">Nouveau patient</span>
          </Link>
        </div>
      </div>
      {patientsBlock}
    </div>
  );
};

export default PatientsPage;

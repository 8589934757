import React, { useMemo, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdOutlineEdit } from 'react-icons/md';
import {
  useGetPractitionerContactsQuery,
  usePatchPractitionerContactMutation,
  useDeletePractitionerContactMutation,
} from '@@/services/practitionerContact';
import { selectInputSpecialities } from '@@/constants/practitioner';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import EditableCell from '@@/components/Table/EditableCell';
import { Filter, partialMatchFilter } from '@@/components/Table/Filter';

const columnHelper = createColumnHelper<Unpatient.PractitionerContact>();

const PractitionerContactsPage: React.FC = () => {
  const { data: practitionerContacts } = useGetPractitionerContactsQuery();
  const [deletePractitionerContact, { isSuccess: isDeletionSuccess }] =
    useDeletePractitionerContactMutation();

  const [patchPractitionerContact, { isSuccess: isPatchSuccess }] =
    usePatchPractitionerContactMutation();

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success('Le contact a bien été supprimé');
      window.location.replace(window.location.href);
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success('Le contact a bien été modifié');
      window.location.replace(window.location.href);
    }
  }, [isPatchSuccess]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => <p className=""></p>,
        cell: EditableCell,
        meta: {
          width: 'w-0',
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor('name', {
        header: () => <p className="mx-2 text-xs sm:text-sm">Nom</p>,
        cell: EditableCell,
        meta: {
          width: 'w-60',
        },
      }),
      columnHelper.accessor('speciality', {
        header: 'Spécialité',
        cell: EditableCell,
        filterFn: (row, columnId, filterValue) => {
          if (!filterValue) {
            return true;
          }

          const cellValue =
            row.original[columnId as keyof Unpatient.PractitionerContact];

          return !!selectInputSpecialities
            .find((i) => i.id === cellValue)
            ?.label?.toLowerCase()
            ?.includes(filterValue?.toLowerCase());
        },
        meta: {
          type: 'select',
          options: [{ id: '', label: '' }].concat(selectInputSpecialities),
          width: 'w-40',
        },
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => <p className="mx-2 text-xs sm:text-sm">Email</p>,
        filterFn: partialMatchFilter,
        cell: EditableCell,
        meta: {
          width: 'w-fit',
        },
      }),
      columnHelper.accessor('phone', {
        id: 'phone',
        header: () => <p className="mx-2 text-xs sm:text-sm">Tel</p>,
        cell: EditableCell,
        meta: {
          width: 'w-32',
        },
      }),

      columnHelper.accessor('city', {
        id: 'city',
        header: () => <p className="mx-2 text-xs sm:text-sm">Ville</p>,
        cell: EditableCell,
        filterFn: partialMatchFilter,
        meta: {
          width: 'w-fit',
        },
      }),
      columnHelper.accessor('notes', {
        id: 'notes',
        header: () => <p className="mx-2 text-xs sm:text-sm">Notes</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 3,
          width: 'w-fit',
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'edit',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div className="mx-2">
              <Link
                to={`/practitioner-contacts/${id}`}
                className="flex flex-row items-center justify-center w-fit border-2 border-green-500 bg-green-500 rounded-3xl p-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdOutlineEdit color="white" />
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'delete',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div className="mx-2">
              <button
                onClick={() => {
                  confirm('Voulez-vous vraiment supprimer ce contact ?') &&
                    deletePractitionerContact(id);
                }}
                className="flex flex-row items-center justify-center w-fit border-2 border-red-500 bg-red-500 rounded-3xl p-1"
              >
                <TiDeleteOutline color="white" />
              </button>
            </div>
          );
        },
      }),
    ],
    [deletePractitionerContact],
  );

  const data = useMemo(() => {
    if (!practitionerContacts?.length) {
      return [];
    }

    return practitionerContacts;
  }, [practitionerContacts]);

  const [sorting, setSorting] = useState<SortingState>([
    {
      desc: false,
      id: 'name',
    },
  ]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    meta: {
      updateData: (id: string, columnId: string, value: string) => {
        const body = {
          [columnId]: value,
        };

        patchPractitionerContact({ id, ...body });
      },
    },
  });

  return (
    <div className="p-4 w-full overflow-scroll">
      <div className="block w-fit ml-auto">
        <Link
          to="/practitioner-contacts/new"
          className="bg-cyan-400 p-2 rounded-lg h-fit"
        >
          <span className="text-white">Nouveau contact</span>
        </Link>
      </div>

      <table className="my-8">
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-center mx-2">
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <span className="pl-2">↑</span>,
                          desc: <span className="pl-2">↓</span>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} />
                      ) : null}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-center mx-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PractitionerContactsPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { useViewPrescriptionPDFQuery } from '@@/services/prescription';

const AuthenticatedPrescriptionPDFLink: React.FC<{
  prescriptionId?: string;
  className?: string;
  children?: any;
}> = ({ prescriptionId = '', className, children }) => {
  const { data: pdfUrl } = useViewPrescriptionPDFQuery(prescriptionId, {
    skip: !prescriptionId,
  });

  return (
    <Link
      to={pdfUrl || ''}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </Link>
  );
};

export default AuthenticatedPrescriptionPDFLink;

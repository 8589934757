import React from 'react';
import {
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Layout from '@@/components/Layout';
import PrivateRoute from '@@/components/PrivateRoute';
import SigninPage from '@@/pages/SigninPage';
import MePage from '@@/pages/MePage';
import CalendarPage from '@@/pages/CalendarPage';
import PatientsPage from '@@/pages/PatientsPage';
import PatientPage from '@@/pages/PatientPage';
import NewPatientPage from '@@/pages/NewPatientPage';
import ConversationsPage from '@@/pages/ConversationsPage';
import ConversationPage from '@@/pages/ConversationPage';
import PlanPage from '@@/pages/PlanPage';
import PlanEditPage from '@@/pages/PlanEditPage';
import PatientSummaryPage from '@@/pages/PatientSummaryPage';
import PrescriptionPage from '@@/pages/PrescriptionPage';
import ReferralPage from '@@/pages/ReferralPage';
import InvoicePage from '@@/pages/InvoicePage';
import AuditPage from '@@/pages/AuditPage';
import MetricsPage from '@@/pages/MetricsPage';
import FaxesPage from '@@/pages/FaxesPage';
import FaxPage from '@@/pages/FaxPage';
import ResetPasswordPage from '@@/pages/ResetPasswordPage';
import ChangePasswordPage from '@@/pages/ChangePasswordPage';
import JoinUsPage from '@@/pages/JoinUsPage';
import ManifestoPage from '@@/pages/ManifestoPage';
import ScheduledMessagesPage from '@@/pages/ScheduledMessagesPage';
import ScheduledMessagePage from '@@/pages/ScheduledMessagePage';
import TemplateMessagesPage from '@@/pages/TemplateMessagesPage';
import NewTemplateMessagePage from '@@/pages/NewTemplateMessagePage';
import PractitionerContactsPage from '@@/pages/PractitionerContactsPage';
import PractitionerContactPage from '@@/pages/PractitionerContactPage';
import ICSCalendarPage from '@@/pages/ICSCalendarPage';
import TasksPage from '@@/pages/TasksPage';
import TaskPage from '@@/pages/TaskPage';
import EventPage from '@@/pages/EventPage';
import DoctolibPage from '@@/pages/Doctolib';
import Fallback from '@@/components/Fallback';

Sentry.init({
  dsn: 'https://aca7ad5076ab2d7fd5a60eb7c041513c@o4506553284689920.ingest.sentry.io/4506701270155264',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Fallback />,
    children: [
      {
        path: '/',
        index: true,
        element: <SigninPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/change-password',
        element: <ChangePasswordPage />,
      },
      {
        path: '/join-us',
        element: <JoinUsPage />,
      },
      {
        path: '/manifesto',
        element: <ManifestoPage />,
      },
      {
        path: '/me',
        element: <PrivateRoute />,
        children: [{ index: true, element: <MePage /> }],
      },
      {
        path: '/calendar',
        element: <PrivateRoute />,
        children: [{ index: true, element: <CalendarPage /> }],
      },
      {
        path: '/patients',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <PatientsPage />,
          },
          {
            path: 'new',
            element: <NewPatientPage />,
          },
          {
            path: ':patientId',
            element: <PatientPage />,
          },
          {
            path: ':patientId/summary',
            element: <PatientSummaryPage />,
          },
          {
            path: ':patientId/audit',
            element: <AuditPage />,
          },
          {
            path: ':patientId/ics',
            element: <ICSCalendarPage />,
          },
          {
            path: ':patientId/plans/:planId',
            element: <PlanPage />,
          },
          {
            path: ':patientId/plans/:planId/edit',
            element: <PlanEditPage />,
          },
          {
            path: ':patientId/prescriptions/:prescriptionId',
            element: <PrescriptionPage />,
          },
          {
            path: ':patientId/referrals/:referralId',
            element: <ReferralPage />,
          },
          {
            path: ':patientId/invoices/:invoiceId',
            element: <InvoicePage />,
          },
          {
            path: ':patientId/events/:eventId',
            element: <EventPage />,
          },
        ],
      },
      {
        path: '/conversations',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <ConversationsPage />,
          },
          {
            path: ':server/:user',
            element: <ConversationPage />,
          },
        ],
      },
      {
        path: '/scheduled-messages',
        element: <PrivateRoute />,
        children: [
          { index: true, element: <ScheduledMessagesPage /> },
          {
            path: ':scheduledMessageId',
            element: <ScheduledMessagePage />,
          },
        ],
      },
      {
        path: '/template-messages',
        element: <PrivateRoute />,
        children: [
          { index: true, element: <TemplateMessagesPage /> },
          {
            path: 'new',
            element: <NewTemplateMessagePage />,
          },
        ],
      },
      {
        path: '/practitioner-contacts',
        element: <PrivateRoute />,
        children: [
          { index: true, element: <PractitionerContactsPage /> },
          {
            path: ':practitionerContactId',
            element: <PractitionerContactPage />,
          },
        ],
      },
      {
        path: '/tasks',
        element: <PrivateRoute />,
        children: [
          { index: true, element: <TasksPage /> },
          {
            path: ':taskId',
            element: <TaskPage />,
          },
        ],
      },
      {
        path: '/doctolib',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <DoctolibPage />,
          },
          {
            path: '*',
            element: <DoctolibPage />,
          },
        ],
      },
      {
        path: '/faxes',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <FaxesPage />,
          },
          {
            path: ':faxId',
            element: <FaxPage />,
          },
        ],
      },
      {
        path: '/metrics',
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <MetricsPage />,
          },
        ],
      },
      {
        path: '*',
        element: <SigninPage />,
      },
    ],
  },
]);

export default router;

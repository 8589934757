import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import omit from 'lodash.omit';
import toast from 'react-hot-toast';
import cx from 'classnames';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {
  useCreateUserEventMutation,
  usePatchEventMutation,
  useGetEventQuery,
  EventFormType,
  useDeleteEventDocumentMutation,
} from '@@/services/event';
import { useGetPatientQuery } from '@@/services/user';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import EventForm, { schema } from '@@/components/Forms/EventForm';

const NEW_EVENT = 'new';

const EventPage: React.FC = () => {
  const { patientId, eventId } = useParams();
  const navigate = useNavigate();
  const [createEvent, { isSuccess }] = useCreateUserEventMutation();

  const [patchEvent, { isSuccess: isPatchSuccess }] = usePatchEventMutation();
  const [deleteDocument, { isSuccess: isDocumentDeletionSuccess }] =
    useDeleteEventDocumentMutation();

  const isNewEvent = useMemo(() => eventId === NEW_EVENT, [eventId]);

  const { data: event } = useGetEventQuery(eventId || '', {
    skip: !eventId || isNewEvent,
  });

  const { data: patient } = useGetPatientQuery(patientId || '', {
    skip: !patientId,
  });

  const backLink = useMemo(() => {
    return `/conversations/c.us/${patient?.phone}#evenements-cles`;
  }, [patient?.phone]);

  const form = useForm<EventFormType & { attachments: File[] }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onDeleteDocument = useCallback(() => {
    if (event) {
      deleteDocument(event);
    }
  }, [deleteDocument, event]);

  const onSubmit = useCallback(
    async (data: EventFormType & { attachments: File[] }) => {
      if (patientId) {
        if (event?.id) {
          const { attachments, ...body } = data;

          patchEvent({
            eventId: event.id,
            body,
            attachment: attachments?.[0],
          });
        } else {
          const { attachments, ...body } = data;
          createEvent({
            userId: patientId,
            body,
            attachment: attachments?.[0],
          });
        }
      }
    },
    [createEvent, event?.id, patientId],
  );

  useEffect(() => {
    if (event?.id) {
      const data = omit(event, [
        '_id',
        'id',
        'createdAt',
        'updatedAt',
        'userId',
        'attachmentId',
        'calendlyId',
        'doctolibId',
        'url',
      ]) as EventFormType;

      form.reset(data);
    }
  }, [event?.id, form.reset]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Votre évènement a bien été créé`, {
        position: 'top-right',
      });

      navigate(backLink);
    }
  }, [isSuccess, backLink, navigate]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`Votre évènement a bien été modifié`, {
        position: 'top-right',
      });

      navigate(backLink);
    }
  }, [isPatchSuccess, backLink, navigate]);

  useEffect(() => {
    if (isDocumentDeletionSuccess) {
      toast.success(`La pièce jointe a bien été supprimée`, {
        position: 'top-right',
      });
    }
  }, [isDocumentDeletionSuccess]);

  return (
    <div className="mx-4 w-full">
      <Link className="p-2 my-2 flex flex-row justify-start" to={backLink}>
        <IoIosArrowRoundBack size={20} />
        <span className="ml-1">Retour</span>
      </Link>

      <EventForm
        form={form}
        birthDate={patient?.medicalRecord?.birthDate}
        existingAttachment={event?.attachmentId}
        onDeleteDocument={onDeleteDocument}
      />

      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className={cx(
          'my-2 rounded-lg p-2 bg-cyan-400 ml-auto block',
          form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className="text-white">Valider</span>
      </button>
    </div>
  );
};

export default EventPage;
